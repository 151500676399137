import {css} from "@emotion/css";

import {designColors} from "~/ui";

export const styles = {
  modalWrap: css`
    display: flex;
    justify-content: center;
    padding: 30px 12px;
    overflow-y: auto;
    outline: none;
  `,
  modal: css`
    max-width: 700px;
    width: fit-content;
    margin: auto;
    min-height: 200px;
    background-color: ${designColors.white};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    outline: none;
    overflow: hidden;
  `,
};
