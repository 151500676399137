import React from "react";
import {Box, Modal as MuiModal, Fade, Stack, SxProps, Theme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {cx} from "@emotion/css";

import {TRANSITION_TIME} from "~/constants/constants";
import {BmPrimaryButton, BmWhiteButton} from "~/components/buttons";
import {BmCommonTitle} from "~/components/titles";

import {styles} from "./DialogModal.styles";

export interface BmDialogModalProps {
  sx?: SxProps<Theme>;
  open: boolean;
  loading?: boolean;
  loadingIndicator?: string;
  confirmButtonText: string;
  cancelButtonText?: string;
  defaultTitle?: boolean;
  onConfirmButtonClick: () => void;
  onClose: VoidFunction;
  title?: React.ReactNode | string;
  content?: string;
}

export const BmDialogModal: React.FC<BmDialogModalProps> = ({
  sx,
  open,
  loading,
  loadingIndicator,
  confirmButtonText,
  cancelButtonText,
  defaultTitle = true,
  onConfirmButtonClick,
  onClose,
  title,
  content,
}) => {
  const {t} = useTranslation();

  return (
    <MuiModal open={open} onClose={onClose} className={styles.modalWrap}>
      <Fade in={open} timeout={TRANSITION_TIME}>
        <Box className={styles.modal} sx={{maxWidth: "440px", ...sx}}>
          <Stack spacing={4} useFlexGap>
            <Box>{defaultTitle ? <BmCommonTitle sx={{m: 0}}>{title}</BmCommonTitle> : title}</Box>

            {content && (
              <Box
                className={cx(styles.modalText, "BmDialogModalMessage")}
                dangerouslySetInnerHTML={{__html: content}}
              />
            )}

            <Stack direction="row" spacing={1} useFlexGap>
              <BmPrimaryButton onClick={onConfirmButtonClick} loading={loading} loadingIndicator={loadingIndicator}>
                {confirmButtonText}
              </BmPrimaryButton>
              <BmWhiteButton onClick={onClose}>{cancelButtonText ?? t("buttons.cancel")}</BmWhiteButton>
            </Stack>
          </Stack>
        </Box>
      </Fade>
    </MuiModal>
  );
};
