import {clone as _clone, compose, curry} from "ramda";
import {FieldErrorsImpl, FieldValues} from "react-hook-form";

import {FieldErrorType} from "~/model/helperTypes/Errors";
import {AnyObject} from "~/model/helperTypes/generic";

import {removeNilFromNestedObject, removePropFromNestedObject} from "./objects";
import {notEquals} from "./ramdaHelpers";

const clone = _clone as <T extends AnyObject>(value: T) => T;

export const isErrorsInStep = (errors: FieldErrorType[], formData: AnyObject): boolean => {
  return errors.some(({field}) => {
    return field in formData;
  });
};

export const getErrorsInStep = (errors: FieldErrorType[], formData: AnyObject): FieldErrorType[] => {
  return errors.filter(({field}) => {
    return field in formData;
  });
};

export const onFormValidationErrorCallback = <T extends FieldValues>(errors: FieldErrorsImpl<T>) => {
  const [firstErrorFieldName] = Object.keys(errors);

  document.querySelector(`*[name="${firstErrorFieldName}"]`)?.scrollIntoView({
    block: "center",
    behavior: "smooth",
  });
};

export const onBEFormValidationErrorCallback = (
  errors: FieldErrorType[],
  selector: string = `*[name="{{fieldName}}"]`,
) => {
  const {field} = errors[0];

  document.querySelector(selector.replace("{{fieldName}}", field))?.scrollIntoView({
    block: "center",
    behavior: "smooth",
  });
};

// step 0 - clone each object
// step 1 - remove all Nil fields from each object
// step 2 - remove property 'srcUrl' from each object and all childrens
// (as this prop has a 'hash' part that is always updates)
// step 3 - make a deep comparison of objects
export const getIsFormDirty = (initialFormData: AnyObject, currentFormData: AnyObject): boolean => {
  const removeSrcUrlPropFromNestedObject = curry(removePropFromNestedObject)("srcUrl");

  const initialFormDataDraft = compose(
    removeSrcUrlPropFromNestedObject,
    removeNilFromNestedObject,
    clone,
  )(initialFormData);

  const currentFormDataDraft = compose(
    removeSrcUrlPropFromNestedObject,
    removeNilFromNestedObject,
    clone,
  )(currentFormData);

  return notEquals(initialFormDataDraft, currentFormDataDraft);
};
