import {css} from "@emotion/css";

import {designColors, designTokens, designUtils} from "~/ui";
import {theme} from "~/ui/theme";

export const styles = {
  tableCell: (newMobileDesign: boolean, rightAlign?: boolean, optionsCell?: boolean) => css`
    &.MuiTableCell-root {
      ${designUtils.baseFontStyles};
      position: relative;
      font-weight: ${designTokens.fontWeight.regular};
      padding: 0;
      transition: ${designUtils.transition(0.3)};
      border-bottom: none;
      vertical-align: top;
      height: 0;

      ${optionsCell &&
      css`
        padding: 4px;
      `}

      ${rightAlign &&
      css`
        text-align: right;
      `}

      ${theme.breakpoints.down("md")} {
        display: flex;
        gap: 12px;
        width: auto !important;
        height: auto;
        padding: ${newMobileDesign ? "6px 12px" : "6px 0"};

        text-align: left;

        & > * {
          flex: 1;
        }
      }

      .MuiTableSortLabel-root {
        vertical-align: top;
        .MuiTableSortLabel-icon {
          display: none;
          //this margin is needed because the svg has empty spaces on the sides
          margin-right: -2px;
        }
        &.Mui-active .MuiTableSortLabel-icon {
          display: inline-block;

          color: ${designColors.colorInk};
        }
      }
    }
  `,
  label: css`
    display: none;

    ${theme.breakpoints.down("md")} {
      display: block;
      word-break: break-word;
      font-weight: ${designTokens.fontWeight.semiBold};
    }
  `,
  underLabel: css`
    display: none;

    ${theme.breakpoints.down("md")} {
      display: block;
      word-break: break-word;
      white-space: pre-line;
      line-height: ${designTokens.lineHeight.loose};
      font-weight: ${designTokens.fontWeight.regular};

      margin-top: 6px;
    }
  `,
  text: (optionsCell?: boolean) => css`
    display: grid;
    grid-template-rows: 1fr 1fr;
    flex-grow: 1;
    height: 100%;

    word-break: break-word;
    > * {
      width: 100%;
      padding: 3px 6px;
      border-bottom: 1px solid ${designColors.grey_2};

      &:last-child {
        border-bottom: none;
      }

      ${theme.breakpoints.down("md")} {
        padding: 3px;
        border: none;
      }
    }

    ${optionsCell && theme.breakpoints.down("md")} {
      display: none;
    }
  `,
};
