import {css} from "@emotion/css";
import {rgba} from "polished";

import {designColors, designTokens, designUtils} from "~/ui";

export const styles = {
  modalWrap: css`
    display: flex;
    justify-content: center;
    margin: 30px 0;
    overflow-y: auto;
    outline: none;
  `,
  modal: css`
    width: fit-content;
    margin: auto;
    min-height: 100px;
    min-width: 350px;
    background-color: ${designColors.white};
    box-shadow: 0 0 20px ${rgba(designColors.black, 0.12)};
    padding: 24px;
    border-radius: 10px;
  `,
  modalText: css`
    ${designUtils.baseFontStyles};
    max-width: 350px;
    color: ${designColors.colorInk};
    font-weight: ${designTokens.fontWeight.regular};
  `,
};
