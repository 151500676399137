import {SxProps, Theme} from "@mui/material";

import {BmUnstyledBaseButton} from "~/components/buttons";

export interface BmHelperTextButtonProps {
  sx?: SxProps<Theme>;
  onClick: VoidFunction;
  children?: React.ReactNode;
}

export const BmHelperTextButton: React.FC<BmHelperTextButtonProps> = ({sx, onClick, children}) => {
  return (
    <BmUnstyledBaseButton
      sx={{
        textDecoration: "underline",
        fontSize: "inherit",
        color: "inherit",
        ...sx,
      }}
      onClick={onClick}
    >
      {children}
    </BmUnstyledBaseButton>
  );
};
